import React, { useState, useRef } from 'react'
//import { Link } from 'gatsby'

const MenuDropdown = ({ data, columns }) => {
    const [isExpanded, setToggleExpansion] = useState(false)
    const [setHeight, setHeightState] = useState('0px')

    const content = useRef(null)

    const openMenuItem = () => {
        setToggleExpansion(true)
        setHeightState(
            isExpanded === true ? '0px' : `${content.current.scrollHeight}px`
        )
    }

    const closeMenuItem = () => {
        setToggleExpansion(false)
        setHeightState(
            isExpanded === true ? '0px' : `${content.current.scrollHeight}px`
        )
    }

    const children = data.childItems.nodes.length ? data.childItems.nodes : null

    return (
        <li
            role="presentation"
            className="c-header-menu__item"
            onMouseEnter={openMenuItem}
            onMouseLeave={closeMenuItem}
            onKeyUp={closeMenuItem}
        >
            <a
                className="c-header-menu__item-link has-children"
                href={data.url}
            >
                {data.label}
            </a>

            <ul
                className={`c-header-menu__children has-${columns}-columns ${
                    isExpanded ? 'is-open' : 'is-closed'
                }`}
                ref={content}
                style={{ maxHeight: `${setHeight}` }}
            >
                {children.map((child, index) => (
                    <li key={index} className="c-header-menu__child-item">
                        <a
                            className="c-header-menu__child-item-link"
                            href={child.url}
                        >
                            {child.label}
                        </a>
                    </li>
                ))}
            </ul>
        </li>
    )
}

export default MenuDropdown
